import { Typography, Box } from "@mui/material";
import VideoPlayer from '../../../components/VideoPlayer'

const JaneByrneCamera = (props) => {
    return (
        <Box sx={{ mt: 2 }} >
            <Typography variant="h4" sx={{textAlign:"center"}}>Jane Byrne Interchange Camera</Typography>
            <Box className="video-report">
            <VideoPlayer />
            </Box>
        </Box>
    )

}

const module = {
    routeProps: {
        path: 'JaneByrneCamera',
        component: JaneByrneCamera
    },
    name: 'JaneByrneCamera',
    label: "Jane Byrne Camera",
    title: "Jane Byrne Interchange Camera",
    showOnMenu: true,
    hideFilter: true,
}

export default module;